import { mainRequests } from './custom'
import config from '../config'

export default {
  // Page
  getPage (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_PAGE + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  delPage (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_PAGE + '/' + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  onPressAddPage (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_PAGE, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOnePage (slug) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_PAGE + '/' + slug)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getBanner (size = 1000, from = 0, searchText = '') {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_BANNER + '?size=' + size + '&from=' + from + '&search_text=' + searchText)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPageUpdate (query, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_PAGE + '/' + query, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // admin udirdlaga
  getUser (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_CLIENT + config.BASE_GET_USER + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Exel
  getExelDownload (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_PRODUCTS_EXEL_DOWNLOAD, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Category
  getCategories () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_CATEGORY)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCategoriesAsTree () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_CATEGORY + '?tree=true')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createCategory (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_CATEGORY, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteCategory (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_CATEGORY + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateCategory (id, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_CATEGORY + '/' + id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateAllCategorySort (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_SORT_CATEGORY, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Employee
  getEmployee (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_EMPLOYEE + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneEmployee (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_EMPLOYEE + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUpdateEmployee (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_EMPLOYEE + '/' + body.employee_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createEmployee (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_EMPLOYEE, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteEmployee (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_EMPLOYEE + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Inventory
  getInventory (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_INVENTORY + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneVariant (variantId) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_INVENTORY + '/' + variantId)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Product
  getSearchProductName (text) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_SEARCH_PRODUCT_NAME + '?text=' + text)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProducts (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_PRODUCT + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProductsWithVariant (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_PRODUCTS_WITH_VARIANT + '?search_text=' + query + '&size=10')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProductByBrand (brandId, query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_BRAND_PRODUCTS_WITH_VARIANT + '/' + brandId + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deletePrice (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_POST_ACCEPT_PRICE + '/' + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  cancelAndAccept (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_POST_ACCEPT_PRICE + '/acceptAndCancelAcceptedPrice', body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  acceptedPrice (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_POST_ACCEPT_PRICE + '/' + body.id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  putUpdatePrice (url, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_POST_ACCEPT_PRICE + '/' + url, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  statusPrice (url, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_POST_ACCEPT_PRICE + '/getAllAcceptedProductByBrand' + url, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  statusPriceSupplier (url) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_POST_ACCEPT_PRICE + url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getApprovalPrice (url) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_POST_ACCEPT_PRICE + url)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  postAcceptPrice (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_POST_ACCEPT_PRICE, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  acceptAndCancelAcceptedPrice (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_POST_ACCEPT_PRICE + '/acceptAndCancelAcceptedPrice', body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addProduct (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_PRODUCT, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteProduct (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_PRODUCT + '/' + body.id + '?desc=' + body.desc)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateProduct (productId, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_PRODUCT + '/' + productId, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneProduct (productId) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_PRODUCT + '/' + productId)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getHistory (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_HISTORY + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getWarehousesBySupplierId (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_WAREHOUSES_BY_SUPPLIER_ID, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getHistoryDetail (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_HISTORY + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAllProducts (body, query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_ALL_PRODUCT + query, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProductByVariantId (variantId) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_PRODUCT_BY_VARIANT_ID + '?variant_id=' + variantId)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOptionName (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_OPTIONS_NAME, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPropertiesValue (property) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_PROPRTY_VALUE + '?properties_name=' + property)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPropertiesName (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_PROPRTY_NAME, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOptionValue (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_OPTIONS_VALUE, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Color
  getColors (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_COLOR + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addColor (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_COLOR, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateColor (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_COLOR + '/' + body.color_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneColor (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_COLOR + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteColor (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_COLOR + '/' + body.color_id + '?desc=' + body.desc)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Tag
  getTag (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_TAG + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addTag (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_TAG, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateTag (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_TAG + '/' + body.id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneTag (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_TAG + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteTag (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_TAG + '/' + body.id + '?desc=' + body.desc)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Brand
  getBrands (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_BRAND + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getBrandsBySupplierId (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_BRANDS_BY_SUPPLIER_ID + '?supplier_id=' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addBrand (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_BRAND, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteBrand (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_BRAND + '/' + body.brand_id + '?desc=' + body.desc)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateBrand (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_BRAND + '/' + body.brand_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneBrand (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_BRAND + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // SupplierAdmin
  changePasswordSupplier (query, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.CHANGE_SUPPLIER_ADMIN + query, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  removeUser (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.REMOVE_SUPPLIER_ADMIN + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSupplierAdminsNext (query, payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.GET_SUPPLIER_ADMINS + query, payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSupplierAdmin (userName) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.GET_SUPPLIER_ADMIN + '?user_name=' + userName)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateSupplierAdmins (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.UPDATE_SUPPLIER_ADMIN, payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  confirmSignUp (user) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.CONFIRM_SUPPLIER_ADMIN, user)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Supplier
  getSuppliers (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_SUPPLIER + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAllSuppliers () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_SUPPLIER)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteSupplier (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_SUPPLIER + '/' + body.supplierId + '?desc=' + body.desc)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addSupplier (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_SUPPLIER, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getBrandsBySupplierIds (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_BRANDS_BY_SUPLLIER_IDS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateSupplier (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_SUPPLIER + '/' + body.supplier_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneSupplier (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_SUPPLIER + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSupplierBrands (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ONESUPPLIER_BRANDS + '?supplier_id=' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSuppliersByWarehouseId (warehouseId) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_SUPPLIERS_BY_WAREHOUSE_ID + '?warehouse_id=' + warehouseId)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Variant
  addVariant (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_ADD_VARIANT, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateVariant (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_UPDATE_VARIANT, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setVariantRackPosition (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_SET_VARIANT_RACK_POSITION, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setVariantBarCodeAndExpiredDate (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_SET_VARIANT_BARCODE_AND_EXPIREDDATE, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProductVariant (producId) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_PRODUCT_VARIANT + '?productId=' + producId)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAllVariants () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ALL_VARIANTS)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getExpiredVariants (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.GET_EXPIRED_VARIANTS + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changeProductCount (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_CHANGE_PRODUCT_COUNT, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Transfer
  getUncheckedTransferProducts (transferId = null) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_UNCHECKED_TRANSFER_PRODUCTS + `?transfer_id=${transferId}`)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getTransfers (query = '') {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_TRANSFER + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  getAllRequiredProduct (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ALL_REQURIED_PRODUCT + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  getOneTransfer (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_TRANSFER + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addTransfer (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_TRANSFER, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changeTransfer (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_TRANSFER + '/' + body.transfer_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changeTransferStatus (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_CHANGE_TRANSFER_STATUS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteTransfer (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_TRANSFER + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Return
  getUncheckedReturnProducts (transferId = null) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_UNCHECKED_RETURN_PRODUCTS + `?transfer_id=${transferId}`)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getReturns (query = '') {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_RETURN + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneReturn (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_RETURN + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addReturn (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_RETURN, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changeReturn (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_RETURN + '/' + body.transfer_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changeReturnStatus (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_CHANGE_RETURN_STATUS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteReturn (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_RETURN + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Wareshouse
  getWarehouses (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_WAREHOUSE + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneWarehouse (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_WAREHOUSE + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addWarehouse (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_WAREHOUSE, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateWarehouse (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_WAREHOUSE + '/' + body.warehouse_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteWarehouse (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_WAREHOUSE + '/' + body.warehouse_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Utillity
  imageUpload (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_IMAGE_UPLOAD, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // stats
  getStats () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.GET_STATS)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Users
  getUsers () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_USERS)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  changePassword (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_CHANGE_PASSWORD, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Groups
  getGroups (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_GROUPS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createGroup (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_CREATE_GROUP, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  deleteGroup (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_DELETE_GROUP, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  addUserToGroup (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_ADD_TO_GROUP, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  checkEbarimt (regno) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet('https://rest.toktok.mn/other/checkMerchant?regno=' + regno)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // BASKET
  createBasket (fingerprint) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet(config.BASE_SECOND_PATH + config.BASE_CREATE_BASKET + '/' + fingerprint)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addItemToBasket (fingerprint, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_CREATE_BASKET + '/' + fingerprint + config.BASE_ADD_BASKET_ITEM, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setItemsToBasket (fingerprint, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_CREATE_BASKET + '/' + fingerprint + config.BASE_SET_BASKET_ITEMS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  removeItemFromBasket (fingerprint, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_CREATE_BASKET + '/' + fingerprint + config.BASE_REMOVE_BASKET_ITEM, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createOrder (fingerprint) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_CREATE_ORDER + '/' + fingerprint)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOrdersDetails () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_ORDERS_DETAILS)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addAddressIntoBasket (fingerprint, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_CREATE_BASKET + '/' + fingerprint + config.BASE_ADD_ADDRESS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addUserIntoBasket (fingerprint, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_CREATE_BASKET + '/' + fingerprint + config.BASE_ADD_USER, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addCouponIntoBasket (fingerprint, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPostWithOption(config.BASE_SECOND_PATH + config.BASE_CREATE_BASKET + '/' + fingerprint + config.BASE_ADD_DISCOUNT_TO_BASKET, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  removeCouponFromBasket (fingerprint, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPostWithOption(config.BASE_SECOND_PATH + config.BASE_CREATE_BASKET + '/' + fingerprint + config.BASE_REMOVE_DISCOUNT_TO_BASKET, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // ORDER
  getOrders (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_ORDER + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setFullfillment (productId) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_ORDER_FULLFILLMENT + '/' + productId)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneOrder (orderNumber) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_ORDER + '/' + orderNumber)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Discount
  getDiscounts (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_DISCOUNT + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteDiscount (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_DISCOUNT + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addDiscount (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_ADD_DISCOUNT, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneDiscount (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ONE_DISCOUNT + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // ADDRESS
  searchAddress (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_ADDRESS + '?address=' + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // search_text
  searchSupplier (status) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_SEARCH_TEXT + '?status=' + status)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Banner
  getBanners (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet(config.BASE_SECOND_PATH + config.BASE_BANNER + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addBanners (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_BANNER, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteBanner (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customDelete(config.BASE_SECOND_PATH + config.BASE_BANNER + '/' + body.id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneBanner (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet(config.BASE_SECOND_PATH + config.BASE_BANNER + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateBanner (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPut(config.BASE_SECOND_PATH + config.BASE_BANNER + '/' + body.id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  // Block
  getBlocks (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.SUPER_BLOCK + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  duplicateSuperblock (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.SUPER_BLOCK + '/duplicateSuperblock' + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  getOneBlock (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.SUPER_BLOCK + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  addBlocks (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.SUPER_BLOCK, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  updateBlock (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.SUPER_BLOCK + '/' + body.super_block_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  deleteBlock (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.SUPER_BLOCK + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCollection (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_COLLECTION + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneCollection (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_COLLECTION + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateCollection (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_COLLECTION + '/' + body.collection_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addCollection (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_COLLECTION, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCollectionList (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_COLLECTION_LIST + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteCollection (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_COLLECTION + '/' + body.collection_id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
